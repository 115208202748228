import React from "react"
import { Link } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import Navbar from "../components/navbar"
import Button from "../components/button"
import {
  FaArrowLeft, FaBinoculars,
  FaCalendar,
  FaCog, FaDatabase,
  FaHammer, FaMedkit,
  FaPencilRuler,
  FaQuestionCircle,
} from "react-icons/fa"

const ConsultingPage = () => {
    return (
      <div style={{ color: '#696969', maxWidth: `800px` }}>
        <title>Scott Murray | Ruby on Rails Consulting</title>
        <Navbar>
          <Link to="/"><FaArrowLeft /></Link>
        </Navbar>
        <Header headerText="Consulting" />
        <p>I provide Ruby on Rails consulting occasionally when I have availability, with specific interest in
          improving efficiency and quality in development processes.</p>
        <p>Feel free to book a call below if you'd like to inquire about any of the following -</p>
        <br />

        <span style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
          <FaCog />
          <h3 style={{ marginLeft: '10px', marginBottom: '0px', marginTop: '0px' }}>Automation</h3>
        </span>
        <p style={{ marginLeft: '10px', marginBottom: '30px' }}>
          Setting up of an automated test suite or CI/CD pipeline to streamline your development/deployment
          process.
        </p>

        <span style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
          <FaBinoculars />
          <h3 style={{ marginLeft: '10px', marginBottom: '0px', marginTop: '0px' }}>Code Audit / PR Reviews</h3>
        </span>
        <p style={{ marginLeft: '10px', marginBottom: '30px' }}>
          Get a second pair of eyes on your codebase for recommending general improvements or for PR reviews.
        </p>

        <span style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
          <FaHammer />
          <h3 style={{ marginLeft: '10px', marginBottom: '0px', marginTop: '0px' }}>Refactoring</h3>
        </span>
        <p style={{ marginLeft: '10px', marginBottom: '30px' }}>
          Improve the maintainability and readability of specific areas in your codebase.
        </p>

        <span style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
          <FaPencilRuler />
          <h3 style={{ marginLeft: '10px', marginBottom: '0px', marginTop: '0px' }}>Engineering Process Review</h3>
        </span>
        <p style={{ marginLeft: '10px', marginBottom: '30px' }}>
          Review of your current engineering/development processes and provide recommendations for improvement.
        </p>

        <span style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
          <FaDatabase />
          <h3 style={{
            marginLeft: '10px',
            marginBottom: '0px',
            marginTop: '0px'
          }}>Testing Strategies and Architecture Review</h3>
        </span>
        <p style={{ marginLeft: '10px', marginBottom: '30px' }}>
          Improve your testing processes and architectural designs to build more robust applications.
        </p>
        <span style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
          <FaMedkit />
          <h3 style={{ marginLeft: '10px', marginBottom: '0px', marginTop: '0px' }}>Diagnosing Performance Issues or Bugs</h3>
        </span>
        <p style={{ marginLeft: '10px', marginBottom: '30px' }}>
          Identify and fix performance bottlenecks and bugs that slow down your application.
        </p>

        <span style={{ display: 'flex', alignItems: 'center', color: 'white' }}>
          <FaQuestionCircle />
          <h3 style={{ marginLeft: '10px', marginBottom: '0px', marginTop: '0px' }}>General Advice</h3>
        </span>
        <p style={{ marginLeft: '10px', marginBottom: '60px' }}>Need some general advice not covered above?  Get in touch below!</p>

        <a href="https://calendly.com/scott-murray" target="_blank">
          <Button>
            Book a call
            <FaCalendar />
          </Button>
        </a>
        <Footer />
        <br />
        <br />
      </div>
    );
}

export default ConsultingPage;